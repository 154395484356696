import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { getStorage, removeStorage, setStorage } from '../../utils/StorageUtil';
import { IFilter } from '../../models/Filter';
import { EStorageKeys } from '../../models/enums';
import { TFiltersReducerInitialState } from '../types/filterTypes';
import { getFiltersListThunk } from '../thunks/filterThunk';

export const initialState: TFiltersReducerInitialState = {
  count: -1,
  filters: [],
  searchQuery: '',
  searchClinicalQuery: '',
  linkedFilterIds: [],
  checkedFilters: [],
  currentFilterId: 0,
  lastCheckedFilterId: '',
  isFetching: false,
  error: null,
};

const FilterReducer = createSlice({
  name: 'Filters',
  initialState,
  reducers: {
    resetFiltersError(state) {
      state.error = null;
    },
    moveFilters(state, action) {
      state.filters = action.payload;
      setStorage<IFilter[]>(EStorageKeys.USER_WORKLIST, action.payload);
    },
    getStorageCheckedFilters(state) {
      state.checkedFilters =
        (getStorage<IFilter[]>(EStorageKeys.CHECKED_FILTERS) as IFilter[]) ||
        [];
    },
    storeCheckedFilters(state, action) {
      state.checkedFilters = action.payload;
      setStorage<IFilter[]>(EStorageKeys.CHECKED_FILTERS, action.payload);
      setStorage<IFilter[]>(EStorageKeys.USER_WORKLIST, state.filters);
    },
    storeFiltersSettings(
      state,
      action: PayloadAction<{
        filterId: string;
        sorting: SortingState;
        filters: ColumnFiltersState;
      }>,
    ) {
      const { sorting, filters, filterId } = action.payload;
      state.checkedFilters = state.checkedFilters.map((filter) => {
        const newFilter = { ...filter };
        if (filter.id === filterId) {
          newFilter.settings = {
            columnSettings: newFilter.settings?.columnSettings || [],
            userGroupsRights: newFilter.settings?.userGroupsRights || [],
            usersRights: newFilter.settings?.usersRights || [],
            sorting,
            filters,
          };
        }
        return newFilter;
      });
      setStorage<IFilter[]>(EStorageKeys.CHECKED_FILTERS, state.checkedFilters);
    },
    setFiltersCombine(state, action) {
      const id = action.payload;
      const index = state.filters.findIndex((filter) => filter.id === id);
      if (index < 0) {
        return;
      }
      const oldCombine = !!state.filters[index].isCombine;
      state.filters[index] = {
        ...state.filters[index],
        isCombine: !oldCombine,
      };
      let isChecked = false;
      state.checkedFilters = state.checkedFilters.map((filter) => {
        const newFilter = { ...filter };
        if (filter.id === id) {
          newFilter.isCombine = !oldCombine;
          isChecked = true;
        }
        return newFilter;
      });
      if (!isChecked && !oldCombine) {
        state.checkedFilters.push(state.filters[index]);
      }
      setStorage<IFilter[]>(EStorageKeys.CHECKED_FILTERS, state.checkedFilters);
      setStorage<IFilter[]>(EStorageKeys.USER_WORKLIST, state.filters);
    },
    getSearchResult(state, action) {
      const searchQuery = action.payload.toLowerCase();
      state.searchQuery = searchQuery;
    },
    resetSearchResult(state) {
      state.searchQuery = '';
    },
    getSearchClinicalResult(state, action) {
      const searchQuery = action.payload.toLowerCase();
      state.searchClinicalQuery = searchQuery;
    },
    resetSearchClinicalResult(state) {
      state.searchClinicalQuery = '';
    },
    getLinkedResult(state) {
      const isCombinedArray = state.checkedFilters
        .filter((i) => i.isCombine)
        .map((i) => +i.id);

      state.linkedFilterIds = isCombinedArray;
    },
    resetLinkedResult(state) {
      state.linkedFilterIds = [];
    },
    setCurrentFilterId(state, action: PayloadAction<number>) {
      state.currentFilterId = action.payload;
      setStorage<IFilter[]>(EStorageKeys.CURRENT_SECTION, action.payload);
    },
    resetCurrentFilterId(state) {
      state.currentFilterId = 0;
      removeStorage(EStorageKeys.CURRENT_SECTION);
    },
    setLastCheckedFilter(state, action: PayloadAction<string>) {
      state.lastCheckedFilterId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFiltersListThunk.pending, (state) => {
        state.isFetching = true;
        state.filters = [];
      })
      .addCase(getFiltersListThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.filters =
            (getStorage<IFilter[]>(EStorageKeys.USER_WORKLIST) as IFilter[]) ||
            action.payload;
          state.checkedFilters = (getStorage<IFilter[]>(
            EStorageKeys.CHECKED_FILTERS,
          ) as IFilter[]) || [action.payload[0]];
          state.count = action.payload.length;
        }
        state.isFetching = false;
      });
  },
});

export const {
  moveFilters,
  getStorageCheckedFilters,
  storeCheckedFilters,
  getSearchResult,
  getSearchClinicalResult,
  resetSearchClinicalResult,
  setFiltersCombine,
  getLinkedResult,
  resetSearchResult,
  resetLinkedResult,
  setCurrentFilterId,
  resetCurrentFilterId,
  setLastCheckedFilter,
  storeFiltersSettings,
} = FilterReducer.actions;

export default FilterReducer.reducer;
