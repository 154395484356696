import { theme } from 'antd';

export const MENU_WIDTH_COLLAPSE = 64;
export const MENU_WIDTH_EXPAND = 200;
export const DEFAULT_MIN_COLUMN_WIDTH = 100;
export const DEFAULT_MIN_COLUMN_TABLE_HEIGHT = 48;
export const TIMER_RELOAD_TIME = 60 * 1000;

export const defaultSortFn = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const PATIENT_LINK =
  'https://demo3.mach7t.com:18888/e/viewer?accession_number=ACC009-01&patient_id=PID0009&viewFromList=e&serviceInstance=MACH7&serviceInstanceParameter=&';

export const configTheme = {
  // 1. Use dark algorithm
  algorithm: theme.darkAlgorithm,
  components: {
    Button: {
      colorTextLightSolid: '#1C2025',
      colorBorder: '#383E48',
      colorBgContainer: '#1C2025',
    },
    Input: {
      colorBorder: '#383E48',
      colorBgContainer: '#1C2025',
    },
    InputNumber: {
      colorBorder: '#383E48',
      colorBgContainer: '#1C2025',
    },
    Select: {
      colorBorder: '#383E48',
      colorBgContainer: '#1C2025',
    },
    DatePicker: {
      colorBorder: '#383E48',
      colorBgContainer: '#1C2025',
    },
    Checkbox: {
      colorPrimary: '#8a85ff',
      colorTextLightSolid: '#A1D103',
      algorithm: true, // Enable algorithm
    },
    Tabs: {
      colorPrimary: '#8a85ff',
      horizontalItemGutter: 0,
    },
    Avatar: {
      groupSpace: 2,
      groupOverlapping: -22,
    },
    Dropdown: {
      colorBgElevated: '#1C2025',
      fontSize: 16,
      controlPaddingHorizontal: 16,
      controlHeight: 44,
    },
    Slider: {
      trackBg: '#535099',
      handleColor: '#535099',
      handleActiveColor: '#8A85FF',
      dotBorderColor: '#8A85FF',
      trackHoverBg: '#8A85FF',
      railBg: '#383E48',
      railHoverBg: '#383E48',
      colorText: '#ffffff',
      handleLineWidth: 2,
      handleLineWidthHover: 2,
      handleSize: 6,
      handleSizeHover: 8,
    },
    Switch: {
      controlHeight: 40,
    },
  },
  token: {
    colorPrimary: '#8A85FF',
    colorLink: '#8A85FF',
  },
};

export const defaultPriorities = {
  blue: 0,
  red: 0,
  yellow: 0,
  orange: 0,
  green: 0,
  purple: 0,
};
