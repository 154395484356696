import { createAsyncThunk } from '@reduxjs/toolkit';
import patientData from '../fakeApi/patients.json';

import { IPatient } from '../../models/Patient';

export const getPatientListThunk = createAsyncThunk<Omit<IPatient, 'timer'>[]>(
  'patient/getPatientList',
  async () => {
    // Simulated API response delay using setTimeout
    return await new Promise((resolve) =>
      setTimeout(
        () =>
          resolve(
            (Object.values(patientData) as Omit<IPatient, 'timer'>[]) || [],
          ),
        1000,
      ),
    );
  },
);

export const getPatientThunk = createAsyncThunk<
  Omit<IPatient, 'timer'>,
  string
>('patient/getPatient', async (id: string) => {
  // Simulated API response delay using setTimeout
  return await new Promise((resolve) =>
    setTimeout(
      () =>
        resolve((patientData as Record<string, Omit<IPatient, 'timer'>>)[id]),
      1000,
    ),
  );
});
