import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getConsiderationListThunk,
  getPatientHistoryThunk,
} from '../thunks/clinicalThunk';
import { TClinicalReducerInitialState } from '../types/clinicalTypes';
import { EExaminations } from '../../models/enums';
import { TCommonData } from '../../models/Consideration';

export const initialState: TClinicalReducerInitialState = {
  getConsideration: {
    considerations: null,
    isFetching: false,
    error: null,
  },

  getPatientClinicalHistory: {
    data: null,
    currentExamination: null,
    isFetching: false,
    error: null,
  },

  unexpectedError: null,
};

const ClinicalReducer = createSlice({
  name: 'Clinical',
  initialState,
  reducers: {
    setCurrentExamination(
      { getPatientClinicalHistory },
      action: PayloadAction<
        (TCommonData & { type: EExaminations | string }) | null
      >,
    ) {
      getPatientClinicalHistory.currentExamination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConsiderationListThunk.pending, ({ getConsideration }) => {
        getConsideration.isFetching = true;
        getConsideration.considerations = null;
      })

      .addCase(
        getConsiderationListThunk.fulfilled,
        ({ getConsideration }, action) => {
          if (action.payload) {
            getConsideration.considerations = action.payload;
          }
          getConsideration.isFetching = false;
        },
      )
      .addCase(
        getPatientHistoryThunk.pending,
        ({ getPatientClinicalHistory }) => {
          getPatientClinicalHistory.isFetching = true;
          getPatientClinicalHistory.data = null;
        },
      )
      .addCase(
        getPatientHistoryThunk.fulfilled,
        ({ getPatientClinicalHistory }, action) => {
          const patientId = action.meta.arg;
          if (action.payload && patientId) {
            const {
              visitHistory,
              medications,
              problemList,
              chartAndSummary,
              aiResults,
            } = action.payload;
            getPatientClinicalHistory.data = {
              visitHistory: visitHistory[String(patientId)],
              medications,
              problemList,
              chartAndSummary,
              physicianNotes: [],
              aiResults,
              radiologyReport: [],
              pathalogy: [],
              labResults: [],
              surgicalHistory: [],
            };
            getPatientClinicalHistory.data.visitHistory?.forEach((item) => {
              if (
                getPatientClinicalHistory.data &&
                item.type !== EExaminations.PROBLEM_LIST &&
                item.type !== EExaminations.MEDICATIONS &&
                item.type !== EExaminations.AI_RESULTS
              ) {
                getPatientClinicalHistory.data[
                  item.type as
                    | EExaminations.RADIOLOGY
                    | EExaminations.LAB_RESULT
                    | EExaminations.SURGICAL_HISTORY
                    | EExaminations.PATHOLOGY
                    | EExaminations.PHYSICIAN_NOTES
                ].push(item);
              }
            });
            getPatientClinicalHistory.currentExamination =
              getPatientClinicalHistory.data.visitHistory[0];
          }
          getPatientClinicalHistory.isFetching = false;
        },
      );
  },
});

export const { setCurrentExamination } = ClinicalReducer.actions;

export default ClinicalReducer.reducer;
