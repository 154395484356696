import { FC, MouseEvent, useCallback } from 'react';
// MODELS
import { IPatient } from '../../../models/Patient';
// REDUX
import { useAppDispatch } from '../../../hooks/hooks';
import { setPieMenuProps } from '../../../redux/reducers/menuReducer';

import { MENU_RADIUS } from '../../InfoMenu/utils';

const InfoMenuCell: FC<{ info: IPatient; disable: boolean }> = ({
  info,
  disable,
}) => {
  const dispatch = useAppDispatch();

  const captureStartPosition = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disable) {
      return;
    }

    const target = e.target as HTMLElement;

    const { top, left, width, height } = target.getBoundingClientRect();

    const x = left + width / 2;
    let y = top + height / 2;

    if (y + MENU_RADIUS > window.innerHeight) {
      y = window.innerHeight - MENU_RADIUS;
    }

    dispatch(
      setPieMenuProps({
        x,
        y,
        info,
      }),
    );
  }, []);

  return (
    <div role="presentation">
      <span
        className="custom-btn-table inline-menu"
        onClick={captureStartPosition}
      />
    </div>
  );
};

export default InfoMenuCell;
