import {
  FC,
  useMemo,
  useRef,
  MouseEvent,
  SyntheticEvent,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

import InfoMenuCell from './InfoMenuCell';

import { IPatient } from '../../../models/Patient';
import { ENoteType, EPatientMode } from '../../../models/enums';

import { changePatientMode } from '../../../redux/reducers/patientReducer';
import {
  setDocumentListProps,
  setNoteProps,
} from '../../../redux/reducers/menuReducer';
import {
  resetSearchClinicalResult,
  setCurrentFilterId,
} from '../../../redux/reducers/filterReducer';

import { ClinicalInfoUrl } from '../../../UrlsConfig';
import { PATIENT_LINK } from '../../../utils/GeneralUtil';

const AvailableActions: FC<{
  info: IPatient;
  sectionId?: number;
  showLink?: boolean;
  disableActions?: boolean;
}> = (props) => {
  const { info, sectionId, showLink = true, disableActions = false } = props;
  const dispatch = useDispatch();
  const cellRef = useRef<HTMLDivElement>(null);

  const linkView = useMemo(
    () => `${ClinicalInfoUrl}/${info.id}?viewOnly=true`,
    [info.id],
  );

  const handleGoToViewPatient = (e: SyntheticEvent, mode: EPatientMode) => {
    e.stopPropagation();
    if (disableActions) {
      return;
    }

    if (info.id === 2) {
      const windowFeatures = 'left=50,top=50,width=700,height=800';
      window.open(PATIENT_LINK, 'newWindow', windowFeatures);
    }
    if (sectionId) {
      dispatch(setCurrentFilterId(sectionId));
    }
    dispatch(resetSearchClinicalResult());
    dispatch(changePatientMode({ id: info.id, mode }));
  };

  // const handleFile = (e: MouseEvent) => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   if (!cellRef.current || disableActions) {
  //     return;
  //   }

  //   const { bottom, right } = cellRef.current.getBoundingClientRect();

  //   dispatch(
  //     setDocumentListProps({
  //       x: right,
  //       y: bottom,
  //       info,
  //     }),
  //   );
  // };

  const handleFile = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!cellRef.current || disableActions) {
      return;
    }

    const { bottom, right } = cellRef.current.getBoundingClientRect();

    dispatch(
      setDocumentListProps({
        x: right,
        y: bottom,
        info,
      }),
    );
  }, []);

  // const handleNote = (e: MouseEvent, type: ENoteType) => {
  //   e.stopPropagation();

  //   if (!cellRef.current || disableActions) {
  //     return;
  //   }

  //   const { bottom, right } = cellRef.current.getBoundingClientRect();

  //   dispatch(
  //     setNoteProps({
  //       x: right,
  //       y: bottom,
  //       info,
  //       type,
  //     }),
  //   );
  // };

  const handleNote = useCallback((e: MouseEvent, type: ENoteType) => {
    e.stopPropagation();
    e.preventDefault();

    if (!cellRef.current || disableActions) {
      return;
    }

    const { bottom, right } = cellRef.current.getBoundingClientRect();

    dispatch(
      setNoteProps({
        x: right,
        y: bottom,
        info,
        type,
      }),
    );
  }, []);

  return (
    <>
      <div className="actions" ref={cellRef}>
        {showLink && (
          <Tooltip
            placement="top"
            trigger="hover"
            title={<p>View</p>}
            destroyTooltipOnHide={true}
          >
            <span className="custom-btn-table link">
              <Link
                to={linkView}
                rel="noreferrer"
                // target="_blank"
                onClick={(e) => handleGoToViewPatient(e, EPatientMode.VIEW)}
              />
            </span>
          </Tooltip>
        )}
        {info.patientsNote &&
          info.patientsNote.map((item, index) => (
            <Tooltip
              key={index}
              placement="top"
              trigger="hover"
              title={<p>Prelims/Notes</p>}
              destroyTooltipOnHide={true}
            >
              <span
                className={`custom-btn-table ${
                  item.type === ENoteType.ER ? 'noteER' : 'noteAI'
                }`}
                onClick={(e) => handleNote(e, item.type)}
              />
            </Tooltip>
          ))}
        <Tooltip
          placement="top"
          trigger="hover"
          title={<p>Exam Documents</p>}
          destroyTooltipOnHide={true}
        >
          <span className="custom-btn-table file" onClick={handleFile} />
        </Tooltip>
        <InfoMenuCell info={info} disable={disableActions} />
      </div>
    </>
  );
};

export default AvailableActions;
