import { createAsyncThunk } from '@reduxjs/toolkit';
import filtersData from '../fakeApi/filters.json';
import usersData from '../fakeApi/users.json';
import columnsData from '../fakeApi/columnSettings.json';

import { IFilter } from '../../models/Filter';
import { getStorage } from '../../utils/StorageUtil';
import { TCorrespondData } from '../types/adminSettingsTypes';
import { EStorageKeys } from '../../models/enums';

export const getFiltersListThunk = createAsyncThunk<IFilter[]>(
  'filter/getFiltersList',
  async () => {
    // Simulated API response delay using setTimeout
    return await new Promise((resolve) =>
      setTimeout(() => {
        const userName = getStorage(EStorageKeys.USERNAME) as string;
        const savedWorlistСorrespond = getStorage<TCorrespondData[]>(
          EStorageKeys.WORKLIST_CORRESPOND,
        ) as TCorrespondData[];
        const savedWorklists =
          (getStorage<IFilter[]>(EStorageKeys.WORKLISTS) as IFilter[]) ||
          filtersData;
        const resultData: IFilter[] = [];
        const defaultSettings = {
          columnSettings: columnsData,
          usersRights: [],
          userGroupsRights: [],
          sorting: [],
          filters: [],
        };

        if (savedWorlistСorrespond && userName) {
          savedWorlistСorrespond.forEach((item) => {
            if (item[userName]) {
              const worklist = savedWorklists.find(
                (filter) => filter.label === item.key,
              );
              if (worklist) {
                const { settings } = worklist;

                resultData.push({
                  ...worklist,
                  settings: {
                    ...(settings || defaultSettings),
                    sorting: [{ id: 'timer', desc: false }],
                    filters: [],
                  },
                });
              }
            }
          });
        } else if (userName) {
          const user = usersData.find(
            (item) => userName === `${item.firstName} ${item.lastName}`,
          );

          if (user) {
            savedWorklists.forEach((filter) => {
              if (user.worklists?.includes(filter.id)) {
                const { settings } = filter;

                resultData.push({
                  ...filter,
                  settings: {
                    ...(settings || defaultSettings),
                    sorting: [{ id: 'timer', desc: false }],
                    filters: [],
                  },
                });
              }
            });
          }
        }

        resolve(resultData);
      }, 1000),
    );
  },
);
