import { createAsyncThunk } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';

import { ILoginRequestData, ILoginResponse } from '../types/authTypes';
import usersData from '../fakeApi/users.json';
import { IUser } from '../../models/User';
import { getStorage, removeTokens, setStorage } from '../../utils/StorageUtil';
import { EStorageKeys } from '../../models/enums';

export const signInThunk = createAsyncThunk<
  ILoginResponse,
  ILoginRequestData,
  { rejectValue: { message: string } }
>(
  'authReducer/signInThunk',
  async (userData: ILoginRequestData, { rejectWithValue }) => {
    const users: IUser[] = await new Promise((resolve) =>
      setTimeout(() => resolve(usersData || []), 500),
    );

    const response = users.find(
      (user) =>
        user.username === userData.username &&
        user.password === userData.password,
    );

    if (!response) {
      return rejectWithValue({ message: 'Authorization error' });
    }

    setStorage(
      EStorageKeys.TOKENS,
      CryptoJS.enc.Utf8.parse(userData.username).toString(CryptoJS.enc.Hex),
    );

    const name = `${response.firstName} ${response.lastName}`;

    setStorage(EStorageKeys.USERNAME, name.trim());

    return { user: response } as ILoginResponse;
  },
);

export const refreshTokenThunk = createAsyncThunk<
  ILoginResponse | undefined,
  boolean | undefined,
  { rejectValue: { message: string } | null }
>('authReducer/refreshTokenThunk', async (_, { rejectWithValue }) => {
  const accessToken: string = getStorage(EStorageKeys.TOKENS) as string;
  const decoded = CryptoJS.enc.Hex.parse(accessToken).toString(
    CryptoJS.enc.Utf8,
  );

  if (!decoded) {
    return rejectWithValue(null);
  }

  const users: IUser[] = await new Promise((resolve) =>
    setTimeout(() => resolve(usersData || []), 500),
  );

  const response = users.find((user) => user.username === decoded);

  if (!response) {
    removeTokens();
    return rejectWithValue({ message: 'Refresh error' });
  }

  return { user: response } as ILoginResponse;
});
