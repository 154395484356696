import {
  ClinicalInfoUrl,
  DashboardUrl,
  FolllowUpUrl,
  WorklistUrl,
} from '../UrlsConfig';

export const hotKeysMap: Record<string, (e: KeyboardEvent) => boolean> = {
  'Z+R': (e: KeyboardEvent) => {
    if (window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+I': (e: KeyboardEvent) => {
    if (window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+P': (e: KeyboardEvent) => {
    if (window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+N': (e: KeyboardEvent) => {
    if (window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+M': (e: KeyboardEvent) => {
    if (
      window.location.href.includes(ClinicalInfoUrl) ||
      window.location.href.includes(WorklistUrl)
    ) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+H': (e: KeyboardEvent) => {
    if (window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+L': (e: KeyboardEvent) => {
    if (window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+D': (e: KeyboardEvent) => {
    if (window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+F': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+1': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+2': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+3': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+4': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+5': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+6': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+7': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+8': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+9': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+O': (e: KeyboardEvent) => {
    if (window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+,': (e: KeyboardEvent) => {
    if (!window.location.href.includes(WorklistUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+.': (e: KeyboardEvent) => {
    if (!window.location.href.includes(ClinicalInfoUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  'Z+/': (e: KeyboardEvent) => {
    if (!window.location.href.includes(FolllowUpUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
  // eslint-disable-next-line quotes
  "Z+'": (e: KeyboardEvent) => {
    if (!window.location.href.includes(DashboardUrl)) {
      e.stopPropagation();
      e.preventDefault();
      return true;
    }
    return false;
  },
};
