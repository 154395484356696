import { FC, useEffect, useState, useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Menu, Layout } from 'antd';

import { signOut } from '../../redux/reducers/authReducer';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { ReactComponent as Logo } from '../../assets/img/logoNV.svg';
import { setStorage } from '../../utils/StorageUtil';
import { NavLinks, NavLinksAdmin, NavLinksBottom } from './NavigationUtil';
import { MENU_WIDTH_COLLAPSE } from '../../utils/GeneralUtil';
import { ClinicalInfoUrl } from '../../UrlsConfig';

import './style.scss';

const MenuSidebar: FC = () => {
  const location = useLocation();
  const { patientId } = useParams();
  const dispatch = useAppDispatch();
  const { user, isAdmin } = useAppSelector(({ auth }) => auth);
  const [activeKey, setActiveKey] = useState<string>('2');

  const { Sider } = Layout;

  const TopLinks = useMemo(
    () => (isAdmin ? NavLinksAdmin : NavLinks),
    [isAdmin],
  );

  if (location && location.pathname.indexOf('login') < 0) {
    setStorage('route', location.pathname);
  }

  const checkUrl = (url: string) => {
    if (url.includes(ClinicalInfoUrl) && !!patientId)
      return `${url}/${patientId}`;
    else return url;
  };

  useEffect(() => {
    let currentLink = TopLinks.find((link) =>
      location.pathname.startsWith(link.url),
    );

    if (!currentLink) {
      currentLink = NavLinksBottom.find((link) =>
        location.pathname.startsWith(link.url),
      );
    }

    if (currentLink) {
      setActiveKey(currentLink.id);
      return;
    }
  }, [location.pathname]);

  const handleClick = ({ key }: { key: string }) => {
    if (key === 'logout') {
      dispatch(signOut());
    }
  };

  const itemsTop = TopLinks.map((link) => ({
    key: link.id,
    icon: <span className={link.iconName} />,
    label: <Link to={checkUrl(link.url)}>{link.title}</Link>,
  }));

  const itemsBottom = [
    {
      key: 'user',
      icon: <span className="avatar-icon" />,
      label: (
        <span>
          {user?.firstName} {user?.lastName}
        </span>
      ),
    },
    ...NavLinksBottom.map((link) => ({
      key: link.id,
      icon: <span className={link.iconName} />,
      label: <Link to={checkUrl(link.url)}>{link.title}</Link>,
    })),
    {
      key: 'logout',
      icon: <span className="logout-icon" />,
      label: <span>Sign out</span>,
    },
  ];

  return (
    <div className="menu-sidebar">
      <Sider
        // collapsible
        collapsed={true}
        collapsedWidth={MENU_WIDTH_COLLAPSE}
      >
        <div className="brand">
          <Logo />
        </div>

        <div className="menu-sidebar-content">
          <Menu
            mode="inline"
            selectedKeys={[activeKey]}
            onSelect={(item) => setActiveKey(item.key.toString())}
            items={itemsTop}
          />

          <Menu
            mode="inline"
            selectedKeys={[activeKey]}
            onSelect={(item) => setActiveKey(item.key.toString())}
            onClick={handleClick}
            className="sidenav-bottom"
            items={itemsBottom}
          />
        </div>
      </Sider>
    </div>
  );
};

export default MenuSidebar;
