import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TFollowUpReducerInitialState } from '../types/followUpTypes';
import { getFollowUpHistoryThunk } from '../thunks/followUpThunk';
import {
  TFollowupRequestSaved,
  TPeerReviewSaved,
} from '../../models/PeerReview';
import { EExamStatus } from '../../models/enums';
import { defaultSortFn } from '../../utils/GeneralUtil';

export const initialState: TFollowUpReducerInitialState = {
  openedPeerReview: false,
  openedPeerLearning: false,
  isFetching: false,
  currentPatientId: '',
  followUpData: {},
  peerFeedback: [],
  actionableFeedback: [],
  followupRequest: [],
};

const FollowUpReducer = createSlice({
  name: 'Menu',
  initialState,
  reducers: {
    openPeerReview(state, action) {
      state.openedPeerReview =
        state.followUpData[action.payload]?.examHistory.length === 2;
    },
    setCurrentPatientId(state, action) {
      state.currentPatientId = String(action.payload);
    },
    closePeerReview(state) {
      state.openedPeerReview = false;
    },
    peerLearningState(state, action) {
      state.openedPeerLearning = action.payload;
    },
    sendFinalScore(state, action: PayloadAction<TPeerReviewSaved>) {
      if (!state.followUpData[state.currentPatientId]) {
        return;
      }
      const { isRequired, score, subOption } = action.payload;
      if (isRequired === 'Yes' && +score > 1) {
        state.followUpData[state.currentPatientId].examHistory.push({
          type: EExamStatus.FINALIZE,
          date: new Date().toISOString(),
          data: action.payload,
        });
        const { patientData } = state.followUpData[state.currentPatientId];
        state.actionableFeedback.push({
          ...patientData,
          ...action.payload,
          userFeedback: `${score}${subOption}`,
        });
        state.actionableFeedback.sort((a, b) =>
          defaultSortFn(a.lastName, b.lastName),
        );
      }
      state.peerFeedback = state.peerFeedback.filter(
        (item) => String(item.id) !== String(state.currentPatientId),
      );
    },
    completePeerReview(state, action: PayloadAction<TPeerReviewSaved>) {
      if (!state.followUpData[state.currentPatientId]) {
        return;
      }
      const historyLength =
        state.followUpData[state.currentPatientId].examHistory.length;
      state.followUpData[state.currentPatientId].examHistory.push({
        id: historyLength,
        type: EExamStatus.PEER_REVIEWED,
        date: new Date().toISOString(),
        data: action.payload,
      });
      const { patientData } = state.followUpData[state.currentPatientId];
      const { score, subOption } = action.payload;
      if (+score > 1) {
        state.peerFeedback.push({
          ...patientData,
          ...action.payload,
          userFeedback: `${score}${subOption}`,
        });
        state.peerFeedback.sort((a, b) =>
          defaultSortFn(a.lastName, b.lastName),
        );
      }
    },
    completePeerLearning(state, action: PayloadAction<TPeerReviewSaved>) {
      if (!state.followUpData[state.currentPatientId]) {
        return;
      }
      const historyLength =
        state.followUpData[state.currentPatientId].examHistory.length;
      state.followUpData[state.currentPatientId].examHistory.push({
        id: historyLength,
        type: EExamStatus.PEER_LEARNING,
        date: new Date().toISOString(),
        data: action.payload,
      });
      const { patientData } = state.followUpData[state.currentPatientId];
      state.peerFeedback.push({
        ...patientData,
        ...action.payload,
        userFeedback: action.payload.meaning,
      });
      state.peerFeedback.sort((a, b) => defaultSortFn(a.lastName, b.lastName));
    },
    sendAddendum(state) {
      if (!state.followUpData[state.currentPatientId]) {
        return;
      }
      state.actionableFeedback = state.actionableFeedback.filter(
        (item) => String(item.id) !== String(state.currentPatientId),
      );
    },
    sendFollowupRequest(state, action: PayloadAction<TPeerReviewSaved>) {
      if (!state.followUpData[state.currentPatientId]) {
        return;
      }
      const { subOption, score } = action.payload;
      if (+score === 2 && +subOption[0] === 1) {
        const data = {
          ...action.payload,
          userFeedback: subOption.substring(1),
        };
        const historyLength =
          state.followUpData[state.currentPatientId].examHistory.length;
        state.followUpData[state.currentPatientId].examHistory.push({
          type: EExamStatus.FINALIZE,
          date: new Date().toISOString(),
          data,
          id: historyLength,
        });
        const { patientData } = state.followUpData[state.currentPatientId];
        state.actionableFeedback.push({
          ...patientData,
          ...data,
        });
        state.actionableFeedback.sort((a, b) =>
          defaultSortFn(a.lastName, b.lastName),
        );
      }
      state.followupRequest = state.followupRequest.filter(
        (item) => String(item.id) !== String(state.currentPatientId),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFollowUpHistoryThunk.pending, (state) => {
        state.isFetching = true;
        state.followUpData = {};
        state.peerFeedback = [];
      })
      .addCase(getFollowUpHistoryThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.followUpData = action.payload;

          Object.values(action.payload).forEach((value) => {
            const { patientData, examHistory } = value;

            const hasFollowup = examHistory.some(
              ({ type }) => type === EExamStatus.FOLLOW_UP_REQUEST,
            );

            const hasPeerFeedback = examHistory.some(
              ({ type }) => type === EExamStatus.PEER_REVIEWED,
            );

            const hasActionableFeedback = examHistory.some(
              ({ type }) => type === EExamStatus.FINALIZE,
            );

            if (hasFollowup) {
              state.followupRequest.push({
                ...patientData,
                ...(examHistory[2].data as unknown as TFollowupRequestSaved),
              });
            }

            if (hasActionableFeedback) {
              const { score, subOption } = examHistory[3]
                .data as TPeerReviewSaved;
              state.actionableFeedback.push({
                ...patientData,
                ...(examHistory[3].data as TPeerReviewSaved),
                userFeedback: `${score}${subOption}`,
              });
            } else if (hasPeerFeedback) {
              const { score, subOption } = examHistory[2]
                .data as TPeerReviewSaved;
              state.peerFeedback.push({
                ...patientData,
                ...(examHistory[2].data as TPeerReviewSaved),
                userFeedback: `${score}${subOption}`,
              });
            }
          });
          state.peerFeedback.sort((a, b) =>
            defaultSortFn(a.lastName, b.lastName),
          );
          state.actionableFeedback.sort((a, b) =>
            defaultSortFn(a.lastName, b.lastName),
          );
        }
        state.isFetching = false;
      });
  },
});

export const {
  completePeerReview,
  openPeerReview,
  closePeerReview,
  sendFinalScore,
  sendAddendum,
  peerLearningState,
  sendFollowupRequest,
  completePeerLearning,
  setCurrentPatientId,
} = FollowUpReducer.actions;

export default FollowUpReducer.reducer;
