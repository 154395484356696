export const HomeUrl = '/';
export const LoginUrl = '/login';
export const ClinicalInfoUrl = '/clinical-info';
export const ClinicalInfoUrlPatientId = ':patientId';
export const WorklistUrl = '/worklist';
export const SettingsUrl = '/settings';
export const ChatUrl = '/chat';
export const DashboardUrl = '/dashboard';
export const FolllowUpUrl = '/follow-up';
export const NotFoundUrl = '/not-found';
export const HelpUrl = '/help';
export const AdminSettingsUrl = '/admin-settings';

export const UserManagementUrl = '/user-management';
export const GridViewUrl = '/user-mapping';

export const WorklistBuilderUrl = '/worklist-builder';
export const WorklistlId = ':worklistId';
export const WorklistSettingsColumns = 'columns-settings';
export const WorklistSettingsFilters = 'filters-settings';
export const WorklistSettingsUsers = 'users-settings';

export const JobManagementUrl = '/job-management';
export const JobId = ':jobId';
