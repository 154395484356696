import React, { MouseEvent, TouchEvent } from 'react';
import { css } from 'styled-components';
import { Slice } from 'react-pie-menu';

import { EWheelMenuItemName } from '../../models/enums';

export const MENU_RADIUS = 150;

export interface ISlice {
  name: EWheelMenuItemName;
  icon: string;
}

export const slice: ISlice[] = [
  { name: EWheelMenuItemName.FOLLOW_UP, icon: 'followUp' },
  { name: EWheelMenuItemName.INCIDENTAL_FINDINGS, icon: 'incidentalFindings' },
  { name: EWheelMenuItemName.PEER_LEARNING, icon: 'peerLearning' },
  { name: EWheelMenuItemName.TEACHING_FILES, icon: 'teachingFiles' },
  { name: EWheelMenuItemName.IMAGE_QA, icon: 'imageQa' },
];

export const getSliceMenuContent = (
  callback: (e: MouseEvent | TouchEvent, name: EWheelMenuItemName) => void,
) => {
  return slice.map((item: ISlice, index: number) => {
    const titleParts = item.name.split(' ');
    return (
      <Slice
        key={index}
        highlight="#A1D103"
        onSelect={(e: MouseEvent | TouchEvent) => callback(e, item.name)}
      >
        <span className={item.icon} />
        <p>
          {titleParts.map((part: string, partIndex: number) => (
            <React.Fragment key={partIndex}>
              {part}
              {partIndex !== titleParts.length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      </Slice>
    );
  });
};

export const theme = {
  pieMenu: {
    container: css``,
    list: css`
      border: 5px solid #89c4f4;
    `,
    item: css`
      outline: rgb(137, 196, 244) solid 4px;
    `,
    center: css`
      color: white;
      border: 4px solid #89c4f4;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  },
  slice: {
    container: css`
      white-space: normal;
      font-size: 12px;
      background-image: radial-gradient(
        transparent 15px,
        rgba(0, 0, 0, 0.8) 15px
      );
      color: #fff;
    `,
    contentContainer: css`
      top: calc(26.5px - 1em);
    `,
    content: css`
      word-break: break-all;
    `,
  },
};
