import React, { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import packageJson from '../package.json';
import Loader from './components/Loader';
import RouterComponent from './components/Router';
import { refreshTokenThunk } from './redux/thunks/authThunk';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import { EStorageKeys } from './models/enums';
import { checkStorage, getStorage, setStorage } from './utils/StorageUtil';

import './assets/scss/main.scss';

const App: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isPending } = useAppSelector(({ auth }) => auth);
  const [isApplicationLoading, setIsApplicationLoading] = useState(true);

  useEffect(() => {
    if (getStorage(EStorageKeys.VERSION) !== packageJson.version) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      setStorage(EStorageKeys.VERSION, packageJson.version);
    }

    if (checkStorage(EStorageKeys.TOKENS)) {
      dispatch(refreshTokenThunk());
    }
    setIsApplicationLoading(false);

    WebFont.load({
      custom: {
        families: ['Open Sans:400;600;700'],
      },
    });
  }, []);

  return (
    <>
      {!isApplicationLoading && !isPending ? (
        <RouterComponent />
      ) : (
        <Loader className="lg" />
      )}
    </>
  );
};

export default App;
